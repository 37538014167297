@import './vars';

button,
.button {
  border: none;
  background-color: transparent;
  padding: 0;
}

.button {
  transition-duration: var(--transition-button-timing);
  &-regular {
    min-width: 180px;
    height: 48px;
    border-radius: 100px;
    font-family: var(--font-Lato-Medium);
    font-size: var(--font-size-1);
    background-color: var(--color-gray-darkest);
    color: var(--color-white);
    &:hover {
      background-color: rgba(var(--color-gray-darkest-rgb), .8);
    }
  }
  &.mod-ghost-darkest {
    --color: var(--color-gray-darkest);
    background-color: transparent;
    border: solid 2px var(--color);
    color: var(--color);
    &:hover {
      background-color: rgba(var(--color-gray-darkest-rgb), .05);
    }
  }
}

@include breakpoint(small) {
  .button {
    &-menu {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 48px;
      width: 48px;
      padding: 0;
      border: none;
      background-color: transparent;
      transition-duration: var(--transition-button-timing);
      outline: none;
      z-index: 5;
      &-line {
        position: absolute;
        height: 2px;
        border-radius: 10px;
        background-color: var(--color-gray-darkest);
        transition-duration: var(--transition-button-timing);
        &:first-of-type {
          top: 18px;
        }
        &:last-of-type {
          bottom: 16px;
        }
        &:first-of-type,
        &:last-of-type {
          width: 18px;
          transition-timing-function: var(--easing-big-bounce);
        }
        &:nth-child(2),
        &:nth-child(3) {
          top: 50%;
          transform: translateY(-50%);
          width: 14px;
          transform: rotate(0);
          transition-timing-function: linear;
        }
      }
      &.mod-menu-open {
        position: absolute;
        right: 20px;
      }
      &.mod-menu-closed {
        .button-menu {
          &-line {
            &:first-of-type,
            &:last-of-type {
              opacity: 0;
              top: 50%;
              transition-timing-function: var(--easing-big-bounce);
            }
            &:nth-child(2),
            &:nth-child(3) {
              width: 19px;
              transition-timing-function: var(--easing-big-bounce);
            }
            &:nth-child(2) {
              transform: rotate(45deg);
            }
            &:nth-child(3) {
              transform: rotate(-45deg);
            }
          }
        }
      }
      &.mod-menu-close {
        position: fixed;
        top: 18px;
        right: 20px;
      }
    }
  }
}

@include breakpoint(mediumlarge) {
  .button {
  }
}