@import './vars';

.footer {
  &-terse {
    font-size: var(--font-size-0);
    line-height: var(--font-height-0);
    font-family: var(--font-Lato-Medium);
  }
  &-terse {
    a {
      color: var(--color-gray-light);
      text-decoration: underline;
      &:hover {
        color: var(--color-gray-light);
        text-decoration: none;
      }
    }
    > div {
      padding: var(--layout-padding-small) var(--layout-padding-small);
      background-color: var(--color-gray-darkest);
      color: var(--color-gray-light);
    }
  }
  &-full {
    padding: var(--layout-padding-medium) 0;
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    &-logo {
      margin-bottom: 25px;
      > img {
        width: 165px;
      }
    }
    &-socials {
      display: flex;
      > a {
        display: inline-block;
        font-family: var(--font-Lato-Medium);
        margin-bottom: 15px;
        img {
          width: 27px;
          margin-right: var(--layout-margin-xsmall);
        }
      }
    }
    &-legal {
      font-size: var(--font-size-0);
      line-height: var(--font-height-0);
      font-family: var(--font-Lato-Medium);
    }
  }
}

@include breakpoint(small) {
  .footer {
    &-terse {
      > div {
        > div:first-of-type {
          span {
            display: none;
          }
        }
        > div:last-of-type {
          margin-top: var(--layout-margin-xsmall);
          span {
            display: inline;
          }
        }
      }
    }
    &-full {
      &-legal {
        > div:first-of-type {
          margin-top: var(--layout-margin-xsmall);
          span {
            display: none;
          }
        }
        > div:last-of-type {
          margin-top: var(--layout-margin-xsmall);
          span {
            display: inline;
          }
        }
      }
      &-socials {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
@include breakpoint(mediumlarge) {
  .footer {
    &-terse {
      background-color: var(--color-gray-darkest);
      > div {
        display: flex;
        max-width: 1080px;
        margin: 0 auto;
        > div:first-of-type {
          margin-right: auto;
        }
        br {
          display: none;
        }
      }
    }
    &-full {
      &-socials {
        display: flex;
        > a {
          margin-right: var(--layout-margin-medium);
        }
      }
      &-legal {
        display: flex;
        br {
          display: none;
        }
        > div:first-of-type {
          margin-right: auto;
        }
        > div:last-of-type {
          span {
            display: inline;
          }
        }
      }
    }
  }
}