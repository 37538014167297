@import './vars';

input,
textarea,
select {
  padding: 8px;
  border: solid 1px var(--color-gray-medium-light);
  border-radius: 3px;
  background-color: var(--color-white);
  &:focus {
    border-color: var(--color-gray-medium);
  }
}

label {
  margin-bottom: var(--layout-margin-xsmall);
}

textarea {
  min-height: 185px;
}

label,
input,
textarea {
  font-family: var(--font-Lato-Medium);
  font-size: var(--font-size-1);
  line-height: var(--font-height-1);
}
