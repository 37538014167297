@import 'vars';
@import 'layout';

body[data-section="advertise"] {
  background-color: var(--color-gray-light);

  .colorful-section {
    animation: graychange 8s infinite;
    animation-direction: alternate;
  }
  .menu-mobile-overlay {
    opacity: .8;
  }

  @include breakpoint(small) {
    .nav-wrapper {
      position: relative;
      z-index: 5;
    }
    .advertise {
      display: flex;
      &-cta {
        text-align: center;
        .button {
          width: 100%;
          margin: var(--layout-margin-medium) 0 var(--layout-margin-small) 0;
        }
        &-secondary {
          position: relative;
          left: 5px;
          display: inline;
        }
      }
      &-section-1 {
        padding-bottom: var(--layout-padding-large);
        > div {
          &:first-child {
            margin-top: var(--layout-margin-large);
          }
        }
        &-content {
          position: relative;
          z-index: 3;
          padding-bottom: var(--layout-padding-large);
        }
        &-offset {
          margin-top: -650px;
        }
        &-correction {
          position: relative;
          top: 650px;
        }
      }
      &-section-2 {
        margin-top: 620px;
      }
      &-list {
        &-explanation {
          margin-bottom: var(--layout-margin-large);
        }
        &-feature {
          padding-bottom: var(--layout-margin-small);
          margin-bottom: var(--layout-margin-small);
          border-bottom: solid 1px var(--color-gray-medium-light);
          h2 {
            position: relative;
            font-size: var(--font-size-4);
            font-family: var(--font-Lato-Heavy);
          }
        }
        &-explanation {
          p {
            margin: 0;
          }
        }
      }
      &-section-3 {
        padding: var(--layout-padding-medium) 0;
        background-color: var(--color-white);
      }
    }
  }

  @include breakpoint(medium) {
    .advertise {
      &-section-1 {
        > div {
          &:last-child {
            margin-top: var(--layout-margin-medium);
          }
        }
      }
    }
  }

  @include breakpoint(large) {
    .advertise {
      &-section-2 {
        overflow-x: hidden;
        margin-top: var(--layout-margin-small);
        padding-top: var(--layout-padding-xlarge);
      }
    }
  }

  @include breakpoint(mediumlarge) {
    .device-wrapper {
        perspective: 300px;
        .device {
          transform-style: preserve-3d;
          transform: rotateY(-4deg);
        }
      }
    .advertise {
      &-cta {
        display: flex;
        align-items: center;
        margin-top: var(--layout-margin-medium);
        &-secondary {
          margin-left: var(--layout-margin-medium);
          white-space: nowrap;
        }
      }

      &-section-1 {
        overflow: visible;
        &-offset {
          margin-top: -120px;
        }
        &-correction {
          position: relative;
          top: 120px;
        }
        &-content {
          margin: var(--layout-margin-jumbo) var(--layout-margin-small) 0 0;
        }
        > div {
          &:last-child {
            margin-top: var(--layout-margin-large);
          }
        }
      }
      &-list {
        &-feature,
        &-explanation {
          margin-bottom: var(--layout-margin-large);
        }
        &-feature {
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          margin-right: var(--layout-margin-small);
          padding-right: var(--layout-margin-small);
          border-right: solid 1px var(--color-gray-medium-light);
          h2 {
            position: relative;
            top: -5px;
            font-family: var(--font-Lato-Heavy);
          }
        }
        &-explanation {
          p {
            margin: 0;
          }
        }
      }
      &-section-3 {
        background-color: var(--color-white);
        padding: var(--layout-padding-small);
      }
    }
  }
}


