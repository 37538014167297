@import 'vars';
@import 'layout';

body[data-section="ad-details"] {
  background-color: var(--color-gray-light);

  .colorful-section {
    animation: graychange 8s infinite;
    animation-direction: alternate;
  }
  .menu-mobile-overlay {
    opacity: .8;
  }

  .ad-details {
    &-section-1 {
      h1 {
        text-align: center;
      }
    }
    &-section-3 {
      .pills-section {
        margin: var(--layout-margin-small) 0;
        font-family: var(--font-Lato-Medium);
        line-height: var(--font-height-1);
        span {
          display: inline-block;
          min-width: 70px;
          padding: 5px 20px;
          text-align: center;
          margin: 0 var(--layout-margin-xsmall) var(--layout-margin-xsmall) 0;
          border-radius: 40px;
          background-color: var(--color-gray-medium-light);
        }
      }
    }
    &-section-6 {
      background-color: var(--color-white);
    }
  }

  .section-emoji {
    font-size: 100px;
    text-align: center;
  }

  h2 {
    text-align: center;
  }

  .basic-flow {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: var(--layout-margin-medium);
    &-line {
      position: absolute;
      z-index: 0;
      top: 0;
      height: 100%;
      top: 35px;
      left: 35px;
      width: 1px;
      background-color: var(--color-gray-medium-light);
    }
    &-item {
      position: relative;
      display: flex;
      padding-bottom: var(--layout-margin-small);
      min-height: 220px;
      &:last-of-type {
        .basic-flow-line {
          display: none;
        }
      }
    }
    &-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 70px;
        min-width: 70px;
        max-width: 70px;
        border-radius: 70px;
        background-color: var(--color-white);
        font-size: 30px;
        line-height: 0;
      }
    h3 {
      text-align: left;
    }
    .basic-flow-headline,
    .basic-flow-subline {
      text-align: left;
      margin: 0 0 0 var(--layout-margin-small);
    }
    .basic-flow-headline {
      margin-bottom: calc(var(--layout-margin-xsmall) / 2);
    }
    .basic-flow-subline {
      font-family: var(--font-Lato-MediumItalic);
      color: var(--color-gray-medium);
    }
  }

  @include breakpoint(small) {
    h1 {
      margin: var(--layout-margin-medium) 0;
    }
    .nav-wrapper {
      position: relative;
      z-index: 5;
    }
    .ad-details {
      &-section-2,
      &-section-3 {
        padding-top: var(--layout-padding-medium);
        padding-bottom: var(--layout-padding-xxlarge);
      }
      &-section-4 {
        padding-top: var(--layout-padding-medium);
        padding-bottom: var(--layout-padding-xxlarge);
      }
      &-section-5 {
        padding-top: var(--layout-padding-medium);
        padding-bottom: var(--layout-padding-large);
      }
      &-section-6 {
        padding: var(--layout-padding-medium) 0;
      }
      &-cta {
        text-align: center;
        .button {
          width: 100%;
          margin: var(--layout-margin-medium) 0 var(--layout-margin-small) 0;
        }
        &-secondary {
          position: relative;
          left: 5px;
          display: inline;
        }
      }
    }
    .basic-flow {
      margin-bottom: var(--layout-margin-medium);
      &-item:last-of-type {
        padding-bottom: 0;
      }
    }
  }

  @include breakpoint(mediumlarge) {
    .less-wide {
      max-width: 540px;
      margin: 0 auto;
    }
    h2 {
      margin-bottom: var(--layout-margin-medium);
    }
    .basic-flow-item {
      min-height: 160px;
    }
    div.ad-details {
      &-section-1 {
        p {
          text-align: left;
        }
      }
      &-section-2 {
        margin-top: calc(var(--layout-margin-medium) * -1);
        padding-bottom: var(--layout-padding-xxlarge);
      }
      &-section-3 {
        &.inner {
          padding: 0 0 var(--layout-padding-xxlarge);
        }
      }
      &-section-4 {
        padding-bottom: var(--layout-padding-xxlarge);
        &.inner {
          padding: 0 0 var(--layout-padding-xxlarge);
        }
      }
      &-section-5 {
        padding-bottom: var(--layout-padding-xxlarge);
      }
      &-section-6 {
        padding: var(--layout-padding-small) 0;
      }
    }
    .basic-flow {
      &-line {
        position: absolute;
        z-index: 0;
        top: 0;
        height: 100%;
        top: 45px;
        left: 45px;
        width: 1px;
        background-color: var(--color-gray-medium-light);
      }
      &-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 90px;
        min-width: 90px;
        max-width: 90px;
        border-radius: 90px;
        background-color: var(--color-white);
        font-size: 40px;
        line-height: 0;
      }
    }
  }
}
