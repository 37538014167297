@font-face {
  font-family: 'Lato-Medium';
  src: local('Lato-Medium'),
    url(../fonts/Lato-Medium.ttf) format('ttf'),
    url(../fonts/Lato-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Lato-MediumItalic';
  font-style: oblique;
  src: local('Lato-MediumItalic'),
    url(../fonts/Lato-Medium.ttf) format('ttf'),
    url(../fonts/Lato-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Lato-Heavy';
  src: local('Lato-Heavy'),
    url(../fonts/Lato-Heavy.ttf) format('ttf'),
    url(../fonts/Lato-Heavy.woff) format('woff');
}