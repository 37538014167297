@import 'vars';

body[data-section="privacy-policy"],
body[data-section="terms-and-conditions"] {
  h3 {
    margin-top: var(--layout-margin-medium);
    font-family: var(--font-Lato-Heavy);
  }
  ul {
    margin: var(--layout-margin-small) 0 var(--layout-margin-medium);
    li {
      list-style-type: disc;
      margin-left: var(--layout-margin-small);
      margin-bottom: var(--layout-margin-small);
    }
  }
}