@import 'vars';

* {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  width: 100%;
  font-size: var(--font-size-1);
}
body {
  background-color: #fff;
}
main {
  position: relative;
  min-height: 100%;
}

a {
  transition-duration: var(--transition-href-timing);
  font-family: var(--font-Lato-Heavy);
}

h1 {
  font-family: var(--font-Lato-Heavy);
}
h2,
h3,
ul {
  font-family: var(--font-Lato-Medium);
}
p {
  font-family: var(--font-Lato-Medium);
  width: 100%;
  &.oblique {
    font-family: var(--font-Lato-MediumItalic);
  }
}

.white-section {
  background-color: var(--color-white);
}

.flex {
  display: flex;
  &.mod-flex-column {
    flex-direction: column;
  }
}

.slant {
  position: relative;
  width: 100%;
  svg {
    width: 100%;
  }
  path {
    fill: var(--color-gray-light);
  }
  .section-emoji {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.cta {
  a {
    font-family: var(--font-Lato-Medium);
  }
}

.wedge {
  display: block;
  &.mod-wedge-bottom {
    position: relative;
    z-index: -1;
    top: -1px;
    transform: rotate(180deg);
  }
}

.cost-breakdown {
  &-amount {
    font-size: var(--font-size-3);
    span {
      font-size: var(--font-size-0);
      color: var(--color-gray-medium);
    }
  }
  &-text {
    font-size: var(--font-size-1);
    line-height: var(--font-height-1);
    h4 {
      margin-top: var(--layout-margin-xsmall);
      font-family: var(--font-Lato-Heavy);
    }
    span {
      font-family: var(--font-Lato-MediumItalic);
      color: var(--color-gray-medium);
    }
  }
}

.pricing-calculator {
  display: flex;
  border: solid 1px var(--color-gray-medium-light);
  padding-top: var(--layout-padding-small);
  background-color: var(--color-white);
  border-radius: 5px;
  &-inputs {
    display: flex;
    flex-direction: column;
    label {
      display: block;
      margin: 0;
    }
    input {
      width: 100%;
    }
  }
  &-results {
    table {
      width: 100%;
      th {
        font-family: var(--font-Lato-Medium);
        font-size: var(--font-size-0);
        text-transform: uppercase;
        color: var(--color-gray-medium);
      }
      tr {
        border-bottom: solid 1px var(--color-gray-medium-light);
      }
      td {
        font-family: var(--font-Lato-Medium);
        text-align: center;
        height: 45px;
        vertical-align: middle;
      }
      td:nth-child(even) {
        font-family: var(--font-Lato-Heavy);
        border-left: solid 1px var(--color-gray-medium-light);
      }
    }
    &-notice {
      font-family: var(--font-Lato-Medium);
      font-size: var(--font-size-0);
      line-height: var(--font-height-0);
    }
  }
}

a {
  color: var(--color-gray-darkest);
  text-decoration: none;
  .little-arrow {
    position: relative;
    display: inline-block;
    height: 10px;
    width: 7px;
    margin-left: 5px;
    &::before {
      position: absolute;
      height: 2px;
      width: 7px;
      left: 0;
      top: 50%;
      opacity: 0;
      transition-duration: calc(var(--transition-arrow-timing) / 2);
      transform: translateY(-50%);
      background-color: var(--color-gray-darkest);
      content: '';
    }
    &::after {
      position: absolute;
      height: 5px;
      width: 5px;
      right: 3px;
      top: 4px;
      border-top: solid 2px var(--color-gray-darkest);
      border-right: solid 2px var(--color-gray-darkest);
      transition-duration: calc(var(--transition-arrow-timing) / 2);
      transform: rotate(45deg) translateY(-50%);
      content: '';
    }
  }
  &:hover {
    color: rgba(var(--color-gray-darkest-rgb), var(--links-hover-opacity));
    .little-arrow {
      opacity: var(--links-hover-opacity);
      &::before {
        opacity: 1;
        transition-timing-function: var(--easing-big-bounce);
        background-color: var(--color-gray-darkest);
      }
      &::after {
        right: 1px;
        border-color: var(--color-gray-darkest);
        transition-duration: var(--transition-arrow-timing);
      }
    }
  }
}

.device {
  position: relative;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, .25);
  overflow: hidden;
  padding: 8px;
  --device-width: 300px;
  width: var(--device-width);
  height: calc(var(--device-height-ratio) * var(--device-width));
  border-radius: calc(var(--device-outer-corner-ratio) *  var(--device-width));
  margin: 0 auto;
  &-contents,
  video {
    border-radius: calc(var(--device-inner-corner-ratio) *  var(--device-width));
  }
  &-contents {
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: #fff;
  }
}

@keyframes gradientMover {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.loading-thin {
  width: 50%;
  margin: 0 auto;
  height: 1px;
  background: linear-gradient(90deg, rgba(128,255,0,1) 0%, rgba(0,255,64,1) 13%, rgba(0,255,255,1) 26%, rgba(0,64,255,1) 39%, rgba(128,0,255,1) 51%, rgba(255,0,191,1) 66%, rgba(255,0,0,1) 80%, rgba(255,191,0,1) 93%);
  background-size: 600% 600%;
  animation: gradientMover 10s linear infinite;
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}

.slant-block {
  display: block;
}

@include breakpoint(small) {
  .wedge {
    &.mod-wedge-bottom,
    &.mod-wedge-top {
      height: 55px;
      width: 100%;
    }
  }
  .cost-breakdown {
    margin-top: var(--layout-margin-small);
    font-family: var(--font-Lato-Medium);
    &-splitter {
      display: flex;
      margin: var(--layout-margin-xsmall) 0;
      > div:first-of-type,
      > div:last-of-type {
        display: none;
      }
      > div:nth-child(2) {
        margin: 10px 0;
        font-size: var(--font-size-0);
        color: var(--color-gray-medium);
      }
    }
  }
  .pricing-calculator {
    flex-direction: column;
    padding-bottom: 0;
    &-inputs {
      order: 2;
      padding: var(--layout-padding-small);
      > div {
        margin-bottom: var(--layout-margin-small);
      }
    }
    &-results {
      order: 1;
      th {
        padding-bottom: var(--layout-padding-small);
      }
      &-notice {
        padding: var(--layout-padding-xsmall) var(--layout-padding-small);
      }
    }
  }
  .device {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
  h1 {
    font-size: var(--font-size-4);
    line-height: var(--font-height-4);
  }
  h2 {
    font-size: var(--font-size-3);
    line-height: var(--font-height-3);
  }
  h3 {
    font-size: var(--font-size-2);
    line-height: var(--font-height-2);
  }
  p {
    font-size: var(--font-size-1);
    line-height: var(--font-height-1);
    margin-top: var(--layout-margin-small);
    margin-bottom: var(--layout-margin-xsmall);
  }
  .hide-for-small {
    display: none;
  }
  .bottom-cta {
    text-align: center;
    p {
      margin-top: 0;
    }
    .button {
      width: 100%;
      margin: var(--layout-margin-xsmall) 0;
    }
  }
}

.app-store-soon {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  > div {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .5);
    > span {
      color: var(--color-white);
      font-family: var(--font-Lato-Heavy);
      font-size: var(--font-size-1);
      transform: rotate(-7deg);
      text-shadow: 0 5px 5px rgba(0, 0, 0, .75);
    }
  }
}

@include breakpoint(medium) {
  h1 {
    font-size: var(--font-size-4);
    line-height: var(--font-height-4);
  }
  h2 {
    font-size: var(--font-size-3);
    line-height: var(--font-height-3);
  }
  h3 {
    font-size: var(--font-size-2);
    line-height: var(--font-height-2);
  }
  p {
    font-size: var(--font-size-1);
    line-height: var(--font-height-1);
    margin-top: var(--layout-margin-small);
    margin-bottom: var(--layout-margin-xsmall);
  }
}

@include breakpoint(large) {
  h1 {
    font-size: var(--font-size-5);
    line-height: var(--font-height-5);
  }
  h2 {
    font-size: var(--font-size-4);
    line-height: var(--font-height-4);
  }
  h3 {
    font-size: var(--font-size-2);
    line-height: var(--font-height-2);
  }
  p {
    font-size: var(--font-size-1);
    line-height: var(--font-height-1);
    margin-top: var(--layout-margin-small);
    margin-bottom: var(--layout-margin-xsmall);
  }
}

@include breakpoint(mediumlarge) {
  .hide-for-mediumlarge {
    display: none;
  }
  .wedge {
    &.mod-wedge-bottom,
    &.mod-wedge-top {
      height: 180px;
      width: 100%;
    }
  }
  .cost-breakdown {
    display: flex;
    margin-top: var(--layout-margin-small);
    margin-bottom: var(--layout-margin-medium);
    font-family: var(--font-Lato-Medium);
    &-splitter {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      align-self: stretch;
      margin: 0 20px 0 10px;
      > div:first-of-type,
      > div:last-of-type {
        width: 1px;
        background-color: var(--color-gray-medium-light);
        height: 100%;
      }
      > div:nth-child(2) {
        margin: 5px 0;
        font-size: var(--font-size-0);
        color: var(--color-gray-medium);
      }
    }
  }
  .pricing-calculator {
    margin-top: var(--layout-margin-small);
    padding-bottom: var(--layout-padding-small);
    &-inputs,
    &-results {
      width: 50%;
    }
    &-inputs {
      padding-left: var(--layout-padding-medium);
      padding-right: var(--layout-padding-small);
      > div {
        margin-bottom: var(--layout-margin-small);
      }
    }
    &-results {
      margin-top: var(--layout-margin-xsmall);
      padding-right: var(--layout-padding-medium);
      padding-left: var(--layout-padding-small);
      th {
        padding-bottom: 3px;
      }
      &-notice {
        margin-top: var(--layout-margin-xsmall);
      }
    }
  }
  .bottom-cta {
    text-align: center;
    p {
      margin: 0 0 var(--layout-margin-xsmall);
    }
  }
}