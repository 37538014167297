
:root {
  // Colors
  --color-gray-darkest: #111;
  --color-gray-medium: #696969;
  --color-gray-medium-light: #d8d8d8;
  --color-gray-light: #fbfbfb;
  --color-white: #fff;

  --color-gray-darkest-rgb: 17, 17, 17;

  // Layout
  --layout-padding-xsmall: 9px;
  --layout-padding-small: 18px;
  --layout-padding-medium: 36px;
  --layout-padding-large: 54px;
  --layout-padding-xlarge: 72px;
  --layout-padding-xxlarge: 90px;
  --layout-padding-jumbo: 144px;

  --layout-margin-xsmall: 9px;
  --layout-margin-small: 18px;
  --layout-margin-medium: 36px;
  --layout-margin-large: 54px;
  --layout-margin-xlarge: 72px;
  --layout-margin-xxlarge: 90px;
  --layout-margin-jumbo: 144px;

  // Font family
  --font-Lato-Medium: 'Lato-Medium', Helvetica, Arial, sans-serif;
  --font-Lato-MediumItalic: 'Lato-MediumItalic', Helvetica, Arial, sans-serif;
  --font-Lato-Heavy: 'Lato-Heavy', Helvetica, Arial, sans-serif;

  // Font sizes
  // Augmented fourth
  --font-baseline: 18px;
  --font-size-0: 12px;
  --font-height-0: 18px;
  --font-size-1: var(--font-baseline);
  --font-height-1: 27px;
  --font-size-2: 25.45px;
  --font-height-2: 37px;
  --font-size-3: 35.99px;
  --font-height-3: 45px;
  --font-size-4: 50.89px;
  --font-height-4: 60px;
  --font-size-5: 71.96px;
  --font-height-5: 85px;
  --font-size-6: 101.75px;

  // Transitions
  --transition-button-timing: 250ms;
  --transition-href-timing: 150ms;
  --transition-arrow-timing: 150ms;
  --transition-menu: 500ms;

  // Easings
  --easing-big-bounce: cubic-bezier(.65,.38,.59,1.71);
  --easing-menu: cubic-bezier(.71,.01,.39,.99);

  // Links
  --links-hover-opacity: .666;

  // device
  // Relative to width of device
  --device-height-ratio: 2.1;
  --device-outer-corner-ratio: .137;
  --device-inner-corner-ratio: .118;
}

@mixin pastels($h) {
  background-color: hsl($h, 100, 86);
  fill: hsl($h, 100, 86);
}

@mixin grays($h) {
  background-color: hsl($h, 0, 94);
  fill: hsl($h, 0, 94);
}

@mixin breakpoint($size) {
  @if $size == small {
    @media (max-width: 600px) {
      @content;
    }
  }
  @if $size == medium {
    @media only screen and (min-width: 601px) and (max-width: 900px) {
      @content;
    }
  }
  @if $size == large {
    @media only screen and (min-width: 901px) {
      @content;
    }
  }
  @if $size == mediumlarge {
    @media only screen and (min-width: 601px) {
      @content;
    }
  }
}

@keyframes colorchange {
  0%    {@include pastels(0)}
  20%   {@include pastels(60)}
  40%   {@include pastels(120)}
  60%   {@include pastels(180)}
  80%   {@include pastels(240)}
  100%  {@include pastels(300)}
}

@keyframes graychange {
  0%   {@include grays(0)}
  50%  {@include grays(60)}
  100% {@include grays(120)}
}